import React from "react"
import { Link, graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Head from "../components/Head/Head"
import AreaGuideListing from "../components/AreaGuideListing/AreaGuideListing"
import Banner_Cta from "../components/Banner_Cta/Banner_Cta"
import { homeValuation, instantValuation } from "../site/urls"
const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)

const AreaGuideLandingTemplate = ({ data }, props) => {
  const PageData = data?.strapiPage
  const areaGuide = data?.allStrapiAreaGuide?.edges

  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>
     
      <div className="layout-padding-top">
      <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
           {/* <Link to={instantValuation}>Instant Online Valuation</Link> */}
           {/* <div className="vertical-line"></div> */}
           <Link to={homeValuation}>Home Visit Valuation</Link>
           </div>
        </div>
        <div className="banner-img"></div>
     </div>
        <SEO
          title={
            PageData?.seo?.metaTitle
              ? PageData?.seo?.metaTitle
              : PageData?.title
          }
          description={
            PageData?.seo?.metaDescription
              ? PageData?.seo?.metaDescription
              : PageData?.title
          }
        />
        <BreadcrumbModule
          choose_menu={PageData.choose_menu}
          pagename={PageData.title}
        />
        <Head title={PageData?.title} content={PageData?.content} />
        {PageData.add_page_modules?.map((item, i) => {
          return (
            <div>
              {item?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                item.select_module === "areaguide" && (
                  <AreaGuideListing areaGuideData={areaGuide} />
                )}
              {item?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER" && (
                <Banner_Cta {...item} />
              )}
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default AreaGuideLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER {
          __typename
          ...CTA_BANNERFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          select_module
          __typename
        }
      }
    }
    allStrapiAreaGuide(filter: { publish: { eq: true } }) {
      edges {
        node {
          ...AreaGuideFragment
        }
      }
    }
  }
`

import { Link } from "gatsby"
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap"
import ImageModule from "../../modules/image-render"
import { areaGuides } from "../../site/urls"
import "./AreaGuideListing.scss"
import PlayVideo from "../PlayVideo/PlayVideo";
import ScrollAnimation from "react-animate-on-scroll";
const AreaGuideListing = ({ areaGuideData }) => {
  const [isPlay, setPlay] = useState(false)
  return (
    <div className="areaguide-listing-component">
      <Container>
        <div className="areaguide-list-section">
          {areaGuideData?.map((data, index) => {
            let processedImages = JSON.stringify({})
            if (data?.node?.imagetransforms?.tile_image_Transforms) {
              processedImages = data?.node.imagetransforms.tile_image_Transforms
            }
            var imagename = "area-guide.tile_image.area_image"
            return (
              <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={(index+1) * 100} offset={25}>
              <div className="areaguide-card">
                
                  <div className="image-section img-zoom">
                  <Link to={`${areaGuides}${data.node?.slug}/`}>
                    <ImageModule
                      ImageSrc={data?.node?.tile_image}
                      altText={`${
                        data?.node?.tile_image?.alternativeText
                          ? data?.node?.tile_image?.alternativeText
                          : data?.node?.title
                      } banner`}
                      imagetransforms={processedImages}
                      renderer="pic-src"
                      imagename={imagename}
                      strapi_id={data?.node?.strapi_id}
                      classNames="areaguide-image"
                    />
                    </Link>
                     {data?.node?.video_url &&
                        <button onClick={() => setPlay(true)} className="testimonial-play-button">
                        <div className="testimonial-play-btn d-flex align-items-center justify-content-center">
                            <i className="icon icon-play"></i>
                        </div>
                    </button>}
                        {isPlay && data?.node?.video_url && (
                        <PlayVideo
                            isOpen={isPlay}
                            stopPlay={setPlay}
                            videoId=""
                            isCloseFunction={setPlay}
                            videourl={data?.node?.video_url}
                            htmlink={""}
                        />
                    )}
                  </div>
                
                <div className="bold-text">
                  <Link to={`${areaGuides}${data.node?.slug}/`}>
                    {data.node?.title}
                  </Link>
                </div>
              </div>
              </ScrollAnimation>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default AreaGuideListing
